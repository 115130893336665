<template>
  <CatalogCinemas v-bind="catalogProps" :data-block-id :data-block-type />
</template>

<script lang="ts" setup>
import useCmsCatalogConfig from '@cms/composables/use-cms-catalog-config'
import type { LinkParam } from '#gql/default'

export interface CmsBlockCinemasProps {
  id: string
  __typename: 'CmsCinemasCatalogBlock'
  layout: CinemaCardsLayout
  enablePagination?: boolean
  renderAboveTheFold: boolean
  showFallbackResults: boolean
  linkParams: LinkParam[]
  featureFlags?: string[]
  types?: string[]
  city?: string
  search?: string
  itemsPerPage: number
  filters: UrlFilter[]
  preserveFilters: boolean
}

const props = defineProps<CmsBlockCinemasProps>()
const { dataBlockId, dataBlockType, catalogProps } = useCmsCatalogConfig(props)

defineOptions({
  name: 'CmsBlockCinemas',
})
</script>
