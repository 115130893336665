import revive_payload_client_YQ9sFcNzfV from "/home/forge/deployments/kinoheld/live/2024-12-13_13-18-15_c1f71e6/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.10.1_eslint@8.57.0_ioredis@5.4.1_magicast@0._32p7xr7xzo63iq3zkbgn3csvm4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_LOjhdRi6OS from "/home/forge/deployments/kinoheld/live/2024-12-13_13-18-15_c1f71e6/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.10.1_eslint@8.57.0_ioredis@5.4.1_magicast@0._32p7xr7xzo63iq3zkbgn3csvm4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_qAtlCKO5DX from "/home/forge/deployments/kinoheld/live/2024-12-13_13-18-15_c1f71e6/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.10.1_eslint@8.57.0_ioredis@5.4.1_magicast@0._32p7xr7xzo63iq3zkbgn3csvm4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kPc9aoWW5H from "/home/forge/deployments/kinoheld/live/2024-12-13_13-18-15_c1f71e6/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.10.1_eslint@8.57.0_ioredis@5.4.1_magicast@0._32p7xr7xzo63iq3zkbgn3csvm4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_yJFtPtgwhe from "/home/forge/deployments/kinoheld/live/2024-12-13_13-18-15_c1f71e6/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.10.1_eslint@8.57.0_ioredis@5.4.1_magicast@0._32p7xr7xzo63iq3zkbgn3csvm4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_plhT5tuEiC from "/home/forge/deployments/kinoheld/live/2024-12-13_13-18-15_c1f71e6/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.10.1_eslint@8.57.0_ioredis@5.4.1_magicast@0._32p7xr7xzo63iq3zkbgn3csvm4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_xiEj9zc42X from "/home/forge/deployments/kinoheld/live/2024-12-13_13-18-15_c1f71e6/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.10.1_eslint@8.57.0_ioredis@5.4.1_magicast@0._32p7xr7xzo63iq3zkbgn3csvm4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/forge/deployments/kinoheld/live/2024-12-13_13-18-15_c1f71e6/apps/kinoheld/.nuxt/components.plugin.mjs";
import prefetch_client_D55oBDAjNh from "/home/forge/deployments/kinoheld/live/2024-12-13_13-18-15_c1f71e6/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.10.1_eslint@8.57.0_ioredis@5.4.1_magicast@0._32p7xr7xzo63iq3zkbgn3csvm4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_LfWHNMlfNU from "/home/forge/deployments/kinoheld/live/2024-12-13_13-18-15_c1f71e6/node_modules/.pnpm/@nuxt+icon@1.8.2_magicast@0.3.4_rollup@4.21.2_vite@5.4.3_@types+node@22.10.1_terser@5.27.2__v_l43fdio37gpa2gbrocu73ycdhi/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import formkitPlugin_pZqjah0RUG from "/home/forge/deployments/kinoheld/live/2024-12-13_13-18-15_c1f71e6/apps/kinoheld/.nuxt/formkitPlugin.mjs";
import plugin_Aq0U7Ojmqk from "/home/forge/deployments/kinoheld/live/2024-12-13_13-18-15_c1f71e6/node_modules/.pnpm/nuxt-graphql-client@0.2.36_@parcel+watcher@2.4.1_@types+node@22.10.1_graphql-tag@2.12.6_graph_hno2tm3rzi4juabz5yl3my4lxe/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import composition_EelUfX8Gxr from "/home/forge/deployments/kinoheld/live/2024-12-13_13-18-15_c1f71e6/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.10_magicast@0.3.4_rollup@4.21.2_vue-router@4.4.3_vue@3.4.38_typescrip_sxw5pnsq6nieegaq6pdnc2x36e/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_pc3LaVtx0a from "/home/forge/deployments/kinoheld/live/2024-12-13_13-18-15_c1f71e6/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.10_magicast@0.3.4_rollup@4.21.2_vue-router@4.4.3_vue@3.4.38_typescrip_sxw5pnsq6nieegaq6pdnc2x36e/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import google_tag_manager_2R27v5gfAU from "/home/forge/deployments/kinoheld/live/2024-12-13_13-18-15_c1f71e6/layers/base/plugins/google-tag-manager.ts";
import sanitize_html_kIz0q5HPOS from "/home/forge/deployments/kinoheld/live/2024-12-13_13-18-15_c1f71e6/layers/base/plugins/sanitize-html.ts";
import sentry_client_dyxoAk6TFV from "/home/forge/deployments/kinoheld/live/2024-12-13_13-18-15_c1f71e6/layers/base/plugins/sentry.client.ts";
export default [
  revive_payload_client_YQ9sFcNzfV,
  unhead_LOjhdRi6OS,
  router_qAtlCKO5DX,
  payload_client_kPc9aoWW5H,
  navigation_repaint_client_yJFtPtgwhe,
  check_outdated_build_client_plhT5tuEiC,
  chunk_reload_client_xiEj9zc42X,
  components_plugin_KR1HBZs4kY,
  prefetch_client_D55oBDAjNh,
  plugin_LfWHNMlfNU,
  formkitPlugin_pZqjah0RUG,
  plugin_Aq0U7Ojmqk,
  composition_EelUfX8Gxr,
  i18n_pc3LaVtx0a,
  google_tag_manager_2R27v5gfAU,
  sanitize_html_kIz0q5HPOS,
  sentry_client_dyxoAk6TFV
]