import getUtmParams from '@base/utils/get-utm-params'

/**
 * Global redirects middleware that handles various URL patterns and legacy routes
 *
 * Handles the following redirects:
 * 1. Legacy uppercase URLs: /Kino-{city}/{cinema}/vorstellung/{id}
 *    → /kino/{city}/{cinema}/vorstellung/{id}
 *
 * 2. English URLs (from Google): /cinema/{city}/{cinema}/show/{id}
 *    → /kino/{city}/{cinema}/vorstellung/{id}
 *
 * 3. Legacy order URLs: /account/order/{orderId}/{hash}
 *    → /konto/bestellung/{orderId}/{hash}
 *
 * 4. Cinema index redirects: /kino/{city}/{cinema}
 *    → /kino/{city}/{cinema}/vorstellungen
 */

/**
 * Helper to add UTM parameters to URL
 * Filters out undefined values and constructs proper query string
 */
function addUtmToPath(
  path: string,
  urlParams: Record<string, string | undefined>
): string {
  if (Object.keys(urlParams).length === 0) {
    return path
  }
  const definedParams = Object.fromEntries(
    Object.entries(urlParams).filter(([_, value]) => value !== undefined)
  ) as Record<string, string>

  const params = new URLSearchParams(definedParams)
  return `${path}?${params.toString()}`
}

export default defineNuxtRouteMiddleware((to) => {
  // Collect UTM parameters from URL and state
  const urlUtmParams = getUtmParams(
    to.query as Record<string, string | undefined>
  )
  const utmState = useUtmState()
  const utmParams = { ...utmState.value, ...urlUtmParams }

  /**
   * Handle legacy uppercase URLs
   * Example: /Kino-Grevenbroich/Grefi%2520Kino%2520Grevenbroich/vorstellung/140353
   * →        /kino/grevenbroich/grefi-kino-grevenbroich/vorstellung/140353
   */
  if (to.path.startsWith('/Kino-')) {
    const parts = to.path.split('/')
    const citySlug = parts[1].toLowerCase().replace('kino-', '')
    const cinemaSlug = decodeURIComponent(decodeURIComponent(parts[2]))
      .toLowerCase()
      .replace(/\s+/g, '-')
    const subPath = parts[3] || ''
    const id = parts[4] || ''

    const newPath = ['kino', citySlug, cinemaSlug, subPath, id]
      .filter(Boolean)
      .join('/')

    return navigateTo(
      addUtmToPath(
        `/${newPath}${!subPath && !id ? '/vorstellungen' : ''}`,
        utmParams
      )
    )
  }

  /**
   * Handle English URLs (from Google)
   * Example: /cinema/muenchen/royal-filmpalast/show/436270
   * →        /kino/muenchen/royal-filmpalast/vorstellung/436270
   */
  if (to.path.startsWith('/cinema/')) {
    const parts = to.path.split('/')
    const citySlug = parts[2].toLowerCase()
    const cinemaSlug = parts[3].toLowerCase()
    const id = parts[5]

    return navigateTo(
      addUtmToPath(
        `/kino/${citySlug}/${cinemaSlug}/vorstellung/${id}`,
        utmParams
      )
    )
  }

  /**
   * Handle legacy order URLs
   * Example: /account/order/2405980087168/NzI0Nzc2.MTE2NDg2MTI1OTgw.NTk4MTExMjczODA
   * →        /konto/bestellung/2405980087168/NzI0Nzc2.MTE2NDg2MTI1OTgw.NTk4MTExMjczODA
   */
  if (to.path.startsWith('/account/order/')) {
    const parts = to.path.split('/')
    return navigateTo(
      addUtmToPath(`/konto/bestellung/${parts[3]}/${parts[4]}`, utmParams)
    )
  }

  /**
   * Handle cinema index redirects
   * Redirects cinema base URLs to their program page
   * Example: /kino/muenchen/royal-filmpalast
   * →        /kino/muenchen/royal-filmpalast/vorstellungen
   */
  if (
    to.path.match(/^\/kino\/[^/]+\/[^/]+\/?$/) && // Matches /kino/{citySlug}/{cinemaSlug} with optional trailing slash
    !to.path.endsWith('/vorstellungen')
  ) {
    const newPath = to.path.replace(/\/?$/, '/vorstellungen')
    return navigateTo(addUtmToPath(newPath, utmParams), {
      redirectCode: 302,
      external: false,
    })
  }
})
